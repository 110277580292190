import React from 'react';
// import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { FooterContainer, Image } from '../UI';
import ExternalLink from '../Links/ExternalLink';
import NTULogo from '../../imgs/ntulogo.png';
import FBicon from '../../imgs/f_logo_RGB-White_114.png';
import YoutubeIcon from '../../imgs/yt_logo_mono_dark.png';

const FooterMainContent = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  text-align: left;
  div {
    width: 25%;
  }
  .ntulogo {
    align-self: center;
    width: 100%;
    max-width: 255px;
  }
  .social_media {
    display: flex;
    flex-flow: row;
    align-items: center;
  }
  @media screen and (max-width: ${props => props.theme.maxWidth}) {
    flex-flow: column;
    div {
      width: 100%;
    }
    .ntulogo {
      max-width: 100%;
    }
  }
`;
const Footer = () => {
  const { t } = useTranslation();
  return (
    <FooterContainer>
      <FooterMainContent>
        <div className="ntulogo">
          <ExternalLink
            noPadding
            link={t('footer.ntu')}
            linkText={<Image style={{ width: 255 }} src={NTULogo} />}
          />
        </div>
        <div>
          <p>{t('footer.phone')}</p>
          <p>{t('footer.email')}</p>
        </div>
        <div style={{ maxWidth: 270 }}>
          <p>{t('footer.address')}</p>
        </div>
        <div>
          <p>{t('footer.follow_us')}</p>
          <div className="social_media">
            <a
              style={{ paddingLeft: 0 }}
              href="https://www.facebook.com/ntugil/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={FBicon} alt="https://www.facebook.com/ntugil/" style={{ width: 35, marginRight: 20 }} />
            </a>
          </div>
        </div>
      </FooterMainContent>
      <p>{t('footer.copyright')}</p>
    </FooterContainer>
  );
};

export default Footer;
